import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async translate({ params }) {
    const url = new URL("/translate", document.location);
    url.searchParams.append("text", document.getElementById(params.source).value);

    const response = await fetch(url.href);
    if (response.ok) {
      const target = document.getElementById(params.target);
      response.text().then((text) => {
        target.value = text;
        target.dispatchEvent(new Event("input")); //trigger autogrow
      });
    }
  }
}
