import { Controller } from "@hotwired/stimulus";
import HtmlDiff from "htmldiff-js";

function simpleFormat(str) {
  str = str.replace(/\r\n?/, "\n");
  str = str.trim();
  if (str.length > 0) {
    str = str.replace(/\n\n+/g, "</p><p>");
    str = str.replace(/\n/g, "<br />");
    str = "<p>" + str + "</p>";
  }
  return str;
}
export default class extends Controller {
  connect() {
    document.querySelectorAll(`[data-controller*="diff"]`).forEach((el) => {
      this.update({ target: el });
    });
  }

  async update({ target }) {
    const changed = target.value;
    const container = target.closest(".diff-container");
    const original = container.querySelector(".original").innerHTML;
    const diff = container.querySelector(".diff");
    diff.innerHTML = HtmlDiff.execute(simpleFormat(original), simpleFormat(changed));
  }
}
